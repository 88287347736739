import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
import Facesheet from '../single/Facesheet';
import PatientDetails from '../single/PatientDetails';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import PatientChargesHistory from '../single/PatientChargesHistory';

const Patient = () => {
    const location = useLocation();
    const patientData = location.state.patient;
    const mode = location.state.mode;
    const [subMode, setSubMode] = useState('view');
    const navigate = useNavigate();
    const [autoFillChoice, setAutoFillChoice] = useState(location.state.autoFillChoice ? location.state.autoFillChoice : null);
    const [patient, setPatient] = useState(patientData && Object.keys(patientData).length > 0 ? patientData : null);
    const [activeTab, setActiveTab] = useState(mode === 'add' ? 'Facesheet' : 'Details');

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }
    }, [navigate]);

    const handleFacesheetData = (uploadFacesheetChoice, isFacesheetUploaded, autoFillChoice, patientData) => {
        setActiveTab('Details');
        setAutoFillChoice(autoFillChoice);
        if (mode === 'add') {
            setPatient(patientData);
        }
        if (autoFillChoice === true) {
            if (isFacesheetUploaded) {
                setPatient(patientData);
            }
            else {
                setPatient(patient);
            }
        }
        if (isFacesheetUploaded && autoFillChoice === false) {
            let PatientWithFacesheetAlias = patient;
            PatientWithFacesheetAlias.facesheetalias = patientData.facesheetalias;
            setPatient(PatientWithFacesheetAlias);
        }
    }

    const handleTabChange = (tab) => {
        if (mode !== 'view&edit') {
            return;
        }
        setAutoFillChoice(true);
        setActiveTab(tab);
    }

    return (
        <div className={styles.mainContainer}>
            <div className="NavigationContainer">
                <div className="back-button-container">
                    <BackIcon onClick={() => navigate(-1)} className="back-button" />
                </div>
                {mode !== 'add' && <div className="toggle-container">
                    <div className="toggle" onClick={() => setSubMode(subMode === 'view' ? 'edit' : 'view')}>
                        <div className={`toggle-slider ${subMode === 'edit' ? 'active' : ''}`}></div>
                    </div>
                    <span>{subMode === 'view' ? 'View' : 'Edit'} Mode</span>
                </div>}
            </div>
            <div className={styles.whiteContainer}>
                <div className={styles.tabsContainer}>
                    <div onClick={() => handleTabChange('Facesheet')} className={`${styles.tab} ${activeTab === 'Facesheet' ? styles.activeTab : ''}`}>Facesheet</div>
                    <div onClick={() => handleTabChange('Details')} className={`${styles.tab} ${activeTab === 'Details' ? styles.activeTab : ''}`}>Details</div>
                    {mode === 'view&edit' && <div onClick={() => handleTabChange('ChargesHistory')} id={styles.ChargesHistory} className={`${styles.tab} ${activeTab === 'ChargesHistory' ? styles.activeTab : ''}`}>Charges History</div>}
                </div>

                {activeTab === 'Facesheet' && <Facesheet subMode={subMode} mode={mode} patient={patient} handleFacesheetData={handleFacesheetData} />}
                {activeTab === 'Details' && <PatientDetails subMode={subMode} mode={mode} patient={patient} />}
                {activeTab === 'ChargesHistory' && <PatientChargesHistory patient={patient} />}
            </div>
        </div>
    );
};

export default Patient;
