import React, { useEffect, useState } from 'react'
import '../../assets/css/DashboardStats.css'
import { ReactComponent as ActiveIcon } from '../../assets/icons/active-patient.svg'
import { ReactComponent as InpatientIcon } from '../../assets/icons/inpatient.svg'
import { ReactComponent as ConsultIcon } from '../../assets/icons/consult.svg'
import { getTokenFromLocalStorage } from '../../helpers'


const DashboardStats = () => {

    const [active, setActive] = useState('');
    const [inpatient, setInpatient] = useState('');
    const [consult, setConsult] = useState('');

    useEffect(() => {
        fetchDashboardStats();
    }, [])

    const fetchDashboardStats = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/dashboard-stats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            setActive(data.activePatients);
            setInpatient(data.inpatient);
            setConsult(data.consult);
        } else {
            console.error('Error:', response.status);
        }
    }

    return (
        <div className='dashboard-container'>
            <div className='stat-main'>
                <div className='dash-icon'><ActiveIcon height={50} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Active</p>
                    <p className='stat-count'>{active}</p>
                </div>
            </div>

            <div className="border"></div>

            <div className='stat-main'>
                <div className='dash-icon'><InpatientIcon height={25} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Inpatient</p>
                    <p className='stat-count'>{inpatient}</p>
                </div>
            </div>

            <div className="border"></div>

            <div className='stat-main'>
                <div className='dash-icon'><ConsultIcon height={50} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Consult</p>
                    <p className='stat-count'>{consult}</p>
                </div>
            </div>
        </div>
    )
}

export default DashboardStats
