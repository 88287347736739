import React from 'react'
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/PatientList.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, isValidDate } from '../../helpers'

const TableRow = ({ patient }) => {
    const navigate = useNavigate();

    const EditDetails = (patient) => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true } });
    };

    function capitalizeNames(firstname, middlename, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    return (
        <div className={styles.flexRow}>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.1, marginRight: '4px' }}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.5 }}>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.5 }}>{isValidDate(patient.dateofbirth) && convertToCustomDate(patient.dateofbirth)}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 1 }}>{patient.location}</div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.3 }}>{patient.visittype !== 'null' ? patient.visittype : ''}</div>
            <div onClick={() => EditDetails(patient)} className={[styles.flexCell].join(' ')} style={{ flex: 0.3 }}>{patient.status}</div>
            <div onClick={() => EditDetails(patient)} className={styles.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>{patient.facesheetalias ? <Tick fill={'green'} /> : <Cross fill={'red'} />}</div>
        </div >
    )
};

export default TableRow
