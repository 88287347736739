import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo_single.png';
import '../../assets/css/DesktopHomeNavbar.css';

export const DesktopHomeNavbar = () => {
  return (
    <div className='DesktopNavbarContainer'>
      <img className='NavbarLogo' src={logo} alt="Logo" />
      <div className='NavbarLinks'>
        <NavLink to="/" className='NavbarLink'>Home</NavLink>
        <NavLink to="/signin" className='NavbarLink'>Sign In</NavLink>
        <NavLink to="/about" className='NavbarLink'>About</NavLink>
        <NavLink to="/services" className='NavbarLink'>Services</NavLink>
      </div >
      <div className='NavbarLinks'>
        <NavLink to="/contact" className='NavbarLink white-btn'>Contact Us</NavLink>
      </div>
    </div >
  )
};
