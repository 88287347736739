import React, { useState } from 'react';
import ICDFavorites from '../reusable/ICDFavorites';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import styles from '../../assets/css/Utilities.module.css'
import { ReactComponent as TextExpanderIcon } from '../../assets/icons/text-expander.svg';
import { ReactComponent as AppointmentSetterIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as ShortCodesIcon } from '../../assets/icons/pencil.svg';
import { ReactComponent as RightArrowHalfIcon } from '../../assets/icons/right-arrow-half.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import ICDShortCodes from '../reusable/ICDShortCodes';
import TextExpander from '../reusable/TextExpander';
import EmployeeCalendar from '../reusable/EmployeeCalendar';
import AppointmentSetter from '../reusable/AppointmentSetter';

const Utilities = () => {
    const [activeTab, setActiveTab] = useState('');

    return (
        <div className={styles.mainContainer}>
            <div className={styles.navigationContainer}>
                <BackIcon onClick={() => setActiveTab('')} className="back-button" />
                <h3 className={styles.heading}>Utilities</h3>
            </div>
            <div className={styles.whiteContainer}>
                {
                    (activeTab === 'icdFavorites' && <ICDFavorites />) ||
                    (activeTab === 'icdShortcodes' && <ICDShortCodes />) ||
                    (activeTab === 'textExpander' && <TextExpander />) ||
                    (activeTab === 'employeeCalendar' && <EmployeeCalendar />) ||
                    (activeTab === 'appointmentSetter' && <AppointmentSetter />)
                }

                {activeTab === '' && <div className={activeTab !== '' ? [styles.active, styles.utilitiesContainer].join(' ') : styles.utilitiesContainer}>
                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => setActiveTab('icdFavorites')}>
                            <div className={styles.options}>
                                <StarIcon fill={'grey'} />
                                <p>Manage ICD Code Favorites</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => setActiveTab('icdShortcodes')}>
                            <div className={styles.options}>
                                <ShortCodesIcon fill={'grey'} />
                                <p>Manage ICD Short Codes</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => setActiveTab('employeeCalendar')}>
                            <div className={styles.options}>
                                <CalendarIcon fill={'grey'} />
                                <p>Employee Calendar</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => setActiveTab('appointmentSetter')}>
                            <div className={styles.options}>
                                <AppointmentSetterIcon stroke={'grey'} />
                                <p>Appointment Setter</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                    {
                        activeTab === '' && <div className={styles.optionContainer} onClick={() => setActiveTab('textExpander')}>
                            <div className={styles.options}>
                                <TextExpanderIcon stroke={'grey'} />
                                <p>Text Expander</p>
                            </div>
                            <div className={styles.options}>
                                <RightArrowHalfIcon stroke={'var(--purple)'} height={20} width={20} />
                            </div>
                        </div>
                    }

                </div>}


            </div>
        </div >
    );
};

export default Utilities;