import React, { useEffect, useState } from 'react';
import { getTokenFromLocalStorage } from '../../helpers';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import ICDstyles from '../../assets/css/ICDFavorites.module.css';
import styles from '../../assets/css/Diagnosis.module.css';

function ICDFavorites() {
    const [favorites, setFavorites] = useState([]);
    const [searchedDiagnoses, setSearchedDiagnoses] = useState([]);

    useEffect(() => {
        fetchFavorites()
    }, []);

    const fetchFavorites = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/favorite-diagnoses', requestOptions);
            const data = await response.json();
            if (response.ok) {
                setFavorites(data);
            }
            else {
                console.log('error');
            }
        } catch (error) {
        }
    }

    const deleteDiagnosis = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/favorite-diagnoses/' + id, requestOptions);
            const data = await response.json();
            if (response.ok) {
                console.log(data.message);
                fetchFavorites();
            }
            else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleSearch = async (e) => {
        if (e.length === 0) {
            setSearchedDiagnoses([]);
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/search-diagnosis?description=' + e, requestOptions);
            const data = await response.json();
            if (response.ok) {
                setSearchedDiagnoses(data);
            }
            else {
                console.log('error');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addDiagnosis = async (id) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ diagnosisId: id })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/favorite-diagnoses', requestOptions);
            const data = await response.json();
            if (response.ok) {
                console.log(data.message);
                fetchFavorites();
            }
            else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className={ICDstyles.searchBarContainer}>
                <SearchIcon className={ICDstyles.searchIcon} />
                <input checked={true} className={ICDstyles.input} type="text" placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
            </div>

            <div className={ICDstyles.scrollableBlock}>
                <div className={styles.DiagnosisContainer}>
                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Favorite Diagnosis</p>
                            <p className={styles.plus}></p>
                        </div>
                        {
                            favorites.map((item, index) => {
                                return (
                                    <div className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><Trash onClick={() => deleteDiagnosis(item.id)} width={20} height={20} fill={'#E52B50'} /></div>
                                    </div>
                                )

                            })
                        }

                    </div>

                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Diagnosis Search Results</p>
                            <p className={styles.plus}></p>
                        </div>
                        {
                            searchedDiagnoses.map((item, index) => {
                                return (
                                    <div className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><TickPending onClick={() => addDiagnosis(item.id)} width={20} height={20} fill={favorites.some((diagnosis) => diagnosis.code === item.code) ? '#5e3ae4' : 'grey'} /></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ICDFavorites;