import React, { useEffect, useState } from 'react';
import AppointmentSetter from '../reusable/AppointmentSetter/index.js';
import { getTokenFromLocalStorage } from '../../helpers';
import Loader from './Loader';

const AppointmentSetterMain = () => {

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hospitalId, setHospitalId] = useState("1");
    window.scheduler.loading = false;

    useEffect(() => {
        fetchCalendarData();
        window.scheduler.hospitalId = hospitalId;
    }, [hospitalId]);

    const handleEventAdded = () => {
        fetchCalendarData();
    };

    const fetchCalendarData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/appointment-setter/${hospitalId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCalendarEvents(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <Loader /> : <div className='scheduler-container'>
                <div className='select-container'>
                    <select value={hospitalId} onChange={(e) => { setHospitalId(e.target.value) }}>
                        <option selected={hospitalId === "1"} value='1'>Encompass Health Rehabilitation Hospital of Petersburg</option>
                        <option selected={hospitalId === "2"} value='2'>Test hospital</option>
                    </select>
                </div>
                <AppointmentSetter events={calendarEvents} hospitalId={hospitalId} onEventAdded={handleEventAdded} />
            </div>}
        </>
    );
};
export default AppointmentSetterMain;