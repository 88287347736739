import React, { useState, useEffect } from 'react';
import '../../assets/css/Toast.css'; // Import the CSS file for styling

const Toast = ({ showToast, message, type, duration = 3000 }) => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    return (
        <>
            {show && (
                <div className={`toast ${type}`}>
                    <div className="toast-message">{message}</div>
                </div>
            )}
        </>
    );
};

export default Toast;