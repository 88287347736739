import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/ChargesVisitCodes.module.css'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { getTokenFromLocalStorage } from '../../helpers';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';

const ChargesSharedVisit = ({ setShowModal, selectedPatients, reRenderPatients }) => {
    const [sharedVisitUsers, setSharedVisitUsers] = useState([]);
    const [selectedSharedVisits, setSelectedSharedVisits] = useState([]);
    useEffect(() => {
        fetchSharedVisitUsers()
        let SharedVisits = [];
        selectedPatients.map((p) => {
            return p.shared_visits.forEach(sv => {
                SharedVisits.push(sv);
            })
        })
        SharedVisits = SharedVisits.filter((sv, index, self) =>
            index === self.findIndex((t) => t.id === sv.id)
        );

        setSelectedSharedVisits(SharedVisits)
    }, []);

    const fetchSharedVisitUsers = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/user-shared-visits`, requestOptions);
        const users = await res.json();
        setSharedVisitUsers(users);
    }

    const handleSave = async () => {
        let ids_array = [];
        if (selectedSharedVisits.length > 0) {
            selectedPatients.forEach((patient, index) => {
                selectedSharedVisits.forEach((sharedvisit, index) => {
                    ids_array.push({
                        patientId: patient.id,
                        sharedVisitId: sharedvisit.id,
                        charges_page_id: patient.charges_page_id
                    });
                });
                patient.shared_visits = selectedSharedVisits;
            });
        }
        else {
            selectedPatients.forEach((patient, index) => {
                ids_array.push({
                    patientId: patient.id,
                    sharedVisitId: null,
                    charges_page_id: patient.charges_page_id
                });
                patient.shared_visits = [];
            });
        }


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            },
            body: JSON.stringify({
                ids_array
            })
        }
        await fetch(`${process.env.REACT_APP_API_URL}/users/attach-shared-visits-to-patient-id`, requestOptions);
        setShowModal(false);
        reRenderPatients(' Shared Visits Updated! ')
    }

    const handleVisitCode = (VisitCode, mode) => {
        if (mode === "delete") {
            const updatedSV = selectedSharedVisits.filter((sv) => VisitCode.id !== sv.id);
            setSelectedSharedVisits(updatedSV);
        }
        else {
            const alreadyAdded = selectedSharedVisits.find((sv) => VisitCode.id === sv.id);
            if (alreadyAdded) {
                return;
            }
            const updatedSV = [...selectedSharedVisits, VisitCode];
            setSelectedSharedVisits(updatedSV);
        }
    }

    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <h3>Select the Shared Visits :</h3>
                <div className={styles.DiagnosisContainer}>
                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Selected Shared Visits</p>
                            <div className={styles.checkmarkIcon} style={{ border: '1px solid red', borderRadius: '5px' }}><Trash onClick={() => { setSelectedSharedVisits([]) }} width={20} height={20} fill={'red'} /></div>
                        </div>
                        {
                            selectedSharedVisits.map((user, index) => {
                                return (
                                    <div className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{user.name}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><Trash onClick={() => handleVisitCode(user, "delete")} width={20} height={20} fill={'#E52B50'} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Shared Visits</p>
                            <div className={styles.checkmarkIcon}></div>
                        </div>
                        {
                            sharedVisitUsers.map((user, index) => {
                                return (
                                    <div className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{user.name}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><TickPending onClick={() => handleVisitCode(user, "add")} width={20} height={20} fill={selectedSharedVisits.some((sv) => sv.id === user.id) ? '#5e3ae4' : 'grey'} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div onClick={() => handleSave()} className={[styles.button, styles.SaveButton].join(' ')}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ChargesSharedVisit;