import { DesktopHomeNavbar } from './DesktopHomeNavbar';
import { MobileHomeNavbar } from './MobileHomeNavbar';
import { useLocation } from 'react-router-dom';

export const HomeNavbar = () => {
  const location = useLocation();
  if (location.pathname === '/patient-list' || location.pathname === '/patient' || location.pathname === '/charges' || location.pathname === '/profile' || location.pathname === '/utilities') {
    return null;
  }
  return (
    <>
      <DesktopHomeNavbar className="hide-on-mobile" />
      <MobileHomeNavbar />
    </>
  );
};