import React, { useEffect, useState } from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import styles1 from '../../assets/css/Patient.module.css'
import { getTokenFromLocalStorage } from '../../helpers'
import PatientChargesHistoryTableRow from '../reusable/PatientChargesHistoryTableRow'

const PatientChargesHistory = ({ patient }) => {

    const [chargesHistory, setChargesHistory] = useState([]);

    useEffect(() => {
        fetchPatientChargesHistory();
    }, []);

    const fetchPatientChargesHistory = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/patient-charges-history?patientId=${patient.id}`, requestOptions);
        const data = await res.json();
        setChargesHistory(data);
    };

    return (
        <div style={{ justifyContent: 'flex-start', backgroundColor: 'white', height: '100%', borderRadius: '40px' }} className={styles1.content}>
            <h4 style={{ margin: '2rem 0' }}>Patient Charges History</h4>
            <div style={{ fontSize: '0.6rem', width: '100%' }} className={styles.tableContainer}>
                <div id="tableHeader" style={{ padding: '0 0.5rem' }} className={styles.flexRow}>
                    <div className={styles.flexCell} style={{ flex: 0.3 }}>Date of Service</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Visit Codes</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Shared Visits</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Submitter</div>
                    <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Time Stamp</div>
                </div>
                {
                    chargesHistory.map((history, index) =>
                        <PatientChargesHistoryTableRow key={index} history={history} />
                    )
                }
            </div >
        </div>
    )
}

export default PatientChargesHistory
