import React from 'react'
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import styles from '../../assets/css/ChargesTab.module.css'
import { capitalizeNames, convertToCustomDate, isValidDate } from '../../helpers';

const SubmittedChargesHistoryTable = ({ patients, handleTableChange }) => {

    const shared_visits = (patient) => {
        return patient && patient.shared_visits && patient.shared_visits.length > 0 ?
            patient.shared_visits.map((visit, index) => (
                <div key={index}>{visit.name}&nbsp;
                </div>
            )) : '-'
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
            <div className={styles.flexRow} id="tableHeader" style={{ fontSize: '0.6rem', margin: '0 1rem' }}>
                <div className={styles.flexCell} style={{ flex: 0.3 }}>Name</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>Visit Code</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>Shared Visit</div>
                <div className={styles.flexCell} style={{ flex: 0.2 }}>Admit Date</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>Visit Type</div>
                <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.3 }}>Location</div>
                <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>Status</div>
                <div className={styles.flexCell} style={{ flex: 0.2 }}>Facesheet</div>
            </div>
            {patients.map((patient, index) => {
                return (
                    <div className={styles.flexRow} style={{ fontSize: '0.6rem', margin: '0 1rem' }}>
                        <div className={styles.flexCell} style={{ flex: 0.3 }}>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</div>
                        <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>{patient.visit_codes && patient.visit_codes.map(vc => vc.visit_code + ' ')}</div>
                        <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>{shared_visits(patient)}</div>
                        <div className={styles.flexCell} style={{ flex: 0.2 }}>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</div>
                        <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.2 }}>{patient.visittype}</div>
                        <div className={[styles.flexCell, styles.HideMobile].join(' ')} style={{ flex: 0.3 }}>{patient.location}</div>
                        <div className={[styles.flexCell].join(' ')} style={{ flex: 0.2 }}>{patient.status}</div>
                        <div className={styles.flexCell} style={{ flex: 0.2 }}>{patient.facesheetalias ? <Tick fill={'green'} /> : <Cross fill={'red'} />}</div>
                    </div>
                )
            }
            )}
        </div>
    )
}

export default SubmittedChargesHistoryTable
