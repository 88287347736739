import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Charges.module.css'
import styles1 from '../../assets/css/ChargesTab.module.css'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { getPatients, getTokenFromLocalStorage } from '../../helpers/index';
import AddPatientTableRow from './AddPatientTableRow';

const ChargesAddPatient = ({ setShowModal, reRenderPatients }) => {

    const [patients, setPatients] = useState([]);
    const [originalPatients, setOriginalPatients] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState({});

    useEffect(() => {
        getPatients().then(res => {
            setPatients(res);
            setOriginalPatients(res);
        })
    }, [])

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const newSelectedPatients = {};
        patients.forEach(patient => {
            newSelectedPatients[patient.id] = isChecked;
        });
        setSelectedPatients(newSelectedPatients);
    };

    const updateSelectedPatient = (patientId, isSelected) => {
        setSelectedPatients(prev => ({ ...prev, [patientId]: isSelected }));
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm.trim() === '') {
            // If the search term is empty, reset to the original patients list
            setPatients(originalPatients);
            return;
        }

        const filteredPatients = originalPatients.filter(patient => {
            return (
                (patient.firstname && patient.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (patient.middlename && patient.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (patient.lastname && patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });

        setPatients(filteredPatients);
    }

    const handleSave = async () => {
        const selectedPatientIds = Object.keys(selectedPatients).filter(patientId => selectedPatients[patientId]);
        // send the selected patient ids to the users/charges-patients-list endpoint using Promise.all
        let promises = [];
        selectedPatientIds.forEach(patientId => {
            promises.push(
                fetch(`${process.env.REACT_APP_API_URL}/users/charges-patients-list`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                    },
                    body: JSON.stringify({ patientId })
                })
            );
        });

        try {
            await Promise.all(promises);
            reRenderPatients(' Patients Added! ');
        } catch (error) {
            console.log(error);
        }

        // and then set the selectedPatients state to an empty object
        setSelectedPatients({});
        setShowModal(false);
    }

    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <div className={styles.ColumnContainer}>
                    <h4>Add Patients :</h4>
                    <div className={styles.searchBarContainer}>
                        <SearchIcon className={styles.searchIcon} />
                        <input checked={selectAll} className={styles.input} type="text" placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div style={{ fontSize: '0.7rem' }} className={styles1.tableContainer}>
                        <div id="tableHeader" className={styles1.flexRow}>
                            <div className={styles1.flexCell} style={{ flex: 0.1, justifyContent: 'center' }}>
                                <input onChange={handleSelectAll} className={styles1.checkbox} type='checkbox' style={{ height: '15px', width: '15px' }} />
                            </div>
                            <div className={styles1.flexCell} style={{ flex: 0.3 }}>
                                Name
                            </div>
                            <div className={styles1.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>Admit Date</div>
                            <div className={styles1.flexCell} style={{ flex: 0.3, justifyContent: 'center' }}>Location</div>
                        </div >
                        {
                            patients.map((patient, index) => {
                                return (
                                    <AddPatientTableRow
                                        key={patient.id}
                                        patient={patient}
                                        isSelected={selectedPatients[patient.id] || false}
                                        onSelectChange={updateSelectedPatient} />
                                )
                            })
                        }
                    </div>
                    <div onClick={() => handleSave()} className={[styles1.button, styles1.SaveButton].join(' ')}>
                        Save
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChargesAddPatient;