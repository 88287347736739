import React, { useEffect, useState } from 'react';
import Scheduler from '../reusable/Scheduler';
import { getTokenFromLocalStorage } from '../../helpers';
import Loader from './Loader';

const EmployeeCalendar = () => {

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    window.scheduler.loading = false;

    useEffect(() => {
        fetchCalendarData();
    }, []);

    const handleEventAdded = () => {
        fetchCalendarData();
    };

    const fetchCalendarData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/employee-calendar`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCalendarEvents(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <Loader /> : <div className='scheduler-container'>
                <Scheduler events={calendarEvents} onEventAdded={handleEventAdded} />
            </div>}
        </>
    );
};

export default EmployeeCalendar;