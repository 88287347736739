import React, { useEffect, useState } from 'react'
import styles from "../../assets/css/Signup.module.css";
import { useNavigate } from "react-router-dom";
import Toast from "../reusable/Toast";
import { getTokenFromLocalStorage } from '../../helpers';
import Loader from './Loader';

const Profile = () => {
    const navigate = useNavigate();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [title, setTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [changePassword, setChangePassword] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/signin');
        }
        getUserDetails()
    }, [navigate]);

    const getUserDetails = async () => {
        setLoading(true);
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/user-details', requestOptions);
            const data = await response.json();
            if (response.ok) {
                setFirstname(data.firstname);
                setLastname(data.lastname);
                setTitle(data.title);
                setPhone(data.phone);
                setEmail(data.email);
                setLoading(false);
            }
            else {
                setLoading(false);
                showErrorToast('Error fetching user details');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleSubmitSignUp = async (e) => {
        e.preventDefault();
        let errors = {};

        if (changePassword) {
            if (!currentPassword) {
                errors.currentPassword = '*Current Password is required';
            }
            if (!password) {
                errors.password = '*Password is required';
            } else if (password.length < 6 || !/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
                errors.password = '*Password must be at least 6 characters long and contain both digits and letters';
            }
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        if (!firstname) {
            errors.firstname = '*First Name is required';
        }

        if (!lastname) {
            errors.lastname = '*Last Name is required';
        }

        if (!title) {
            errors.title = '*Title is required';
        }

        if (!phone) {
            errors.phone = '*Phone is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        setErrors({});
        const formData = new URLSearchParams();
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('title', title);
        formData.append('phone', phone);
        if (changePassword) {
            formData.append('password', password);
            formData.append('confirmPassword', confirmPassword);
            formData.append('currentPassword', currentPassword);
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            },
            body: formData
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/user-details', requestOptions);
            if (response.ok) {
                showSuccessToast('User details updated successfully');
                setLoading(false);
                if (changePassword) {
                    localStorage.removeItem('token');
                    navigate('/signin');
                }
            }
            else {
                const data = await response.json();
                showErrorToast(data.message);
                setLoading(false);
            }
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }

    };

    return (
        <section style={{ width: '100%' }} className={styles.signupContainer}>
            <div className={styles.signupContent}>
                {loading ? <Loader /> : <form className={styles.signupForm}>
                    <div className={styles.signUpButtonWrapper}>
                        <div className={styles.signUpText}>Profile Details</div>
                        <div style={{ width: '150px' }} className={styles.signUpDivider} />
                    </div>


                    {
                        !loading && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="First Name"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                            {errors.firstname && <div className={styles.error}>{errors.firstname}</div>}
                        </div>
                    }

                    {
                        !loading && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Last Name"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                            {errors.lastname && <div className={styles.error}>{errors.lastname}</div>}
                        </div>
                    }

                    {
                        !loading && <div className={styles.inputWrapper}>
                            <select
                                className={styles.inputField}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            >
                                <option value="" disabled>Select Title</option>
                                <option value="Physician">Physician</option>
                                <option value="Nurse Practitioner">Nurse Practitioner</option>
                                <option value="Physician's Assistant">Physician's Assistant</option>
                                <option value="Operations">Operations</option>
                                <option value="IT Admin">IT Admin</option>
                                <option value="Hospital Staff">Hospital Staff</option>
                            </select>
                            {errors.title && <div className={styles.error}>{errors.title}</div>}
                        </div>
                    }

                    {
                        !loading && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Phone Number"
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            {errors.phone && <div className={styles.error}>{errors.phone}</div>}
                        </div>
                    }

                    {
                        !loading && <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                style={{ cursor: 'not-allowed', color: 'grey' }}
                                placeholder="Email"
                                type="text"
                                value={email}
                                disabled
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    }

                    {
                        !loading && <div style={{ flexDirection: 'row', fontSize: '0.7rem', gap: '0.5rem' }} className={styles.inputWrapper}>
                            <input
                                type="checkbox"
                                checked={changePassword}
                                onChange={(e) => setChangePassword(e.target.checked)}
                            />
                            <p className={styles.changePasswordLabel}>Change Password</p>
                        </div>
                    }

                    {

                        !loading && changePassword &&
                        <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="Current Password"
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            {errors.currentPassword && <div className={styles.error}>{errors.currentPassword}</div>}
                        </div>
                    }

                    {

                        !loading && changePassword &&
                        <div className={styles.inputWrapper}>
                            <input
                                className={styles.inputField}
                                placeholder="New Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className={styles.error}>{errors.password}</div>}
                        </div>
                    }

                    {
                        !loading && changePassword &&
                        <div className={styles.inputWrapper}><input
                            className={styles.inputField}
                            placeholder="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                            {errors.confirmPassword && <div className={styles.error}>{errors.confirmPassword}</div>}
                        </div>
                    }

                    <div className={styles.inputWrapper}>
                        {loading ?
                            <div className={styles.loading}>Please wait...</div> :
                            <button onClick={(e) => handleSubmitSignUp(e)} className={styles.submitButton}>Update</button>}
                    </div>
                </form>}
                {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            </div>
        </section >
    )
}

export default Profile
