import React, { useEffect, useState } from 'react';
import { getTokenFromLocalStorage } from '../../helpers';
import ICDstyles from '../../assets/css/ICDFavorites.module.css';
import styles from '../../assets/css/Diagnosis.module.css';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const ICDShortCodes = () => {

    const [shortCodes, setShortCodes] = useState([]);
    const [searchedDiagnoses, setSearchedDiagnoses] = useState([]);
    const [accordion, setAccordion] = useState(false);

    useEffect(() => {
        fetchShortCodes();
    }, []);

    const fetchShortCodes = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                'Content-Type': 'application/json'
            }
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/icd-short-codes', requestOptions);
            if (response.ok) {
                const shortcodes = await response.json();
                setShortCodes(shortcodes);
            }
        } catch (error) {
            console.log('Error fetching shortcodes:', error);
        }
    }

    const deleteShortCode = async (item) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ short_code: item.short_code, diagnosis_id: item.diagnosis_id })
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/icd-short-codes', requestOptions);
            if (response.ok) {
                console.log('Short code deleted successfully');
                fetchShortCodes();
            }
        } catch (error) {
            console.log('Error fetching shortcodes:', error);
        }
    }

    const addShortCode = async (item) => {
        if (item.newShortCode === null) {
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ short_code: item.newShortCode, diagnosis_id: item.id })
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/icd-short-codes', requestOptions);
            if (response.ok) {
                console.log('Shortcode added successfully');
                fetchShortCodes();
            }
        } catch (error) {
            console.log('Error fetching shortcodes:', error);
        }
    }

    const handleSearch = async (e) => {
        setSearchedDiagnoses([]);
        if (e.length === 0) {
            setSearchedDiagnoses([]);
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/search-diagnosis?description=' + e, requestOptions);
            const data = await response.json();
            if (response.ok) {
                setSearchedDiagnoses(data);
            }
            else {
                console.log('error');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const toggleAccordion = () => {
        setAccordion(!accordion);
    }


    return (
        <>
            <div className={ICDstyles.searchBarContainer}>
                <SearchIcon className={ICDstyles.searchIcon} />
                <input checked={true} className={ICDstyles.input} type="text" placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
            </div>

            <div className={ICDstyles.scrollableBlock}>
                <div className={styles.DiagnosisContainer}>
                    <div className={styles.DiagnosisBlock}>
                        <div onClick={() => toggleAccordion()} className={styles.accordionTitle}>
                            <p>Assigned Short Codes</p>
                            <p className={styles.plus}>{accordion ? '-' : '+'}</p>
                        </div>
                        {
                            accordion && shortCodes.map((item, index) => {
                                return (
                                    <div style={{ minHeight: '41px' }} className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><input className={ICDstyles.shortCodeInput} disabled type="text" value={item.short_code} />&nbsp;&nbsp;&nbsp;<Trash onClick={() => deleteShortCode(item)} width={20} height={20} fill={'#E52B50'} /></div>
                                    </div>
                                )

                            })
                        }

                    </div>

                    <div className={styles.DiagnosisBlock}>
                        <div className={styles.accordionTitle}>
                            <p>Diagnosis Search Results</p>
                            <p className={styles.plus}></p>
                        </div>
                        {
                            searchedDiagnoses.map((item, index) => {
                                return (
                                    <div style={{ minHeight: '41px' }} className={styles.DiagnosisItem} key={index}>
                                        <div className={styles.DiagnosisItemCode}>{item.code}: {item.description}</div>
                                        <div className={styles.DiagnosisItemDescription}> </div>
                                        <div className={styles.checkmarkIcon}><input onChange={(e) => item.newShortCode = e.target.value} value={item.short_code} placeholder="Type Short Code" className={ICDstyles.shortCodeInput} type="text" />&nbsp;&nbsp;&nbsp;<TickPending onClick={() => addShortCode(item)} width={20} height={20} fill={shortCodes.some((diagnosis) => diagnosis.code === item.code) ? '#5e3ae4' : 'grey'} /></div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ICDShortCodes;