import React, { useEffect, useRef, useState } from 'react';
import styles from '../../assets/css/TextExpander.module.css';
import { getTokenFromLocalStorage } from '../../helpers';
import Toast from './Toast';
import Loader from './Loader';

const TextExpander = () => {
    const [shortcut, setShortcut] = useState('');
    const AllShortCuts = useRef([]);
    const [RenderExpansionData, setRenderExpansionData] = useState([]);
    const [expandedText, setExpandedText] = useState('');
    const [listExpandedText, setListExpandedText] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [editingId, setEditingId] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchShortCuts();
    }, []);

    const handleImportClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'application/json';
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        readJsonFile(file);
    };

    const readJsonFile = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const jsonData = JSON.parse(event.target.result);
            importExpansions(jsonData);
        };
        reader.readAsText(file);
    };

    const importExpansions = async (jsonData) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify(jsonData)
        }
        try {
            setLoading(true);
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/import-expansions', requestOptions);
            const data = await response.json();
            if (response.ok) {
                showSuccessToast(data.message);
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                showErrorToast(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
        finally {
            setLoading(false);
        }
    }


    const fetchShortCuts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                AllShortCuts.current = data;
                setRenderExpansionData(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const addExpansion = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ shortcut, expansion: expandedText, edit: false })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/text-expander', requestOptions);
            const data = await response.json();
            if (response.ok) {
                showSuccessToast('Added Successfully!');
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                showErrorToast(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const editExpansion = async (sc, exp, edit = true) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ shortcut: sc, expansion: exp, edit })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/users/text-expander', requestOptions);
            const data = await response.json();
            if (response.ok) {
                showSuccessToast('Edited Successfully!');
                setEditingId('');
                fetchShortCuts();
                console.log(data.message);
            }
            else {
                showErrorToast(data.message);
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const searchShortCut = async () => {
        let textarea = document.getElementById("inputText");
        let cursorPosition = textarea.selectionStart;
        let textBeforeCursor = textarea.value.substring(0, cursorPosition);
        let textAfterCursor = textarea.value.substring(cursorPosition);

        AllShortCuts.current.forEach(sc => {
            if (textBeforeCursor.endsWith(sc.shortcut)) {
                textBeforeCursor = textBeforeCursor.substring(0, textBeforeCursor.length - sc.shortcut.length) + sc.expansion;
                textarea.value = textBeforeCursor + textAfterCursor;
                textarea.selectionStart = textarea.selectionEnd = textBeforeCursor.length;
            }
        })
    }
    const deleteExpansion = async (id) => {
        // e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/text-expander/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });
            if (response.ok) {
                const data = await response.json();
                showSuccessToast(data.message);
                fetchShortCuts();
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleExport = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/export-expansions`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'text_expansions.json';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const sortExpansions = () => {
        const sortedExpansions = [...AllShortCuts.current].sort((a, b) => {
            const comparison = a.shortcut.localeCompare(b.shortcut);
            return sortOrder === 'asc' ? comparison : -comparison;
        });

        setRenderExpansionData(sortedExpansions);
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    }

    return (
        <div className={styles.container}>
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            <div className={styles.headerContainer}>
                <h1 className={styles.colorBlue}>Text Expander</h1>
            </div>
            <textarea onChange={(e) => searchShortCut(e.target.value)} disabled={false} className={styles.textClass} id="inputText" rows="10" cols="50" placeholder="Type your text here..."></textarea>
            <div className={styles.buttonContainer}>
                <button className={styles.buttonClass} onClick={() => document.getElementById("inputText").value = ''}>Clear Text</button>
                <button className={styles.buttonClass} onClick={() => handleExport()}>Export Expansions</button>
                <button onClick={() => handleImportClick()} className={styles.buttonClass}>{loading ? <Loader height={'17px'} width={'17px'} /> : 'Import Expansions'}</button>
            </div>
            <hr />
            <h2 className={styles.colorBlue}>Add New Expansion</h2>
            <label for="shortcut">Shortcut:</label>
            <input className={styles.textClass} onChange={(e) => setShortcut(e.target.value)} value={shortcut} type="text" id="shortcut" placeholder=".shortcut" />
            <br />
            <label for="expansion">Expansion:</label>
            <textarea className={styles.textClass} onChange={(e) => setExpandedText(e.target.value)} value={expandedText} id="expansion" placeholder="Expanded text"></textarea>
            <br />
            <button className={styles.buttonClass} onClick={addExpansion}>Add Expansion</button>
            <p className={[styles.colorBlue, styles.errorMessage].join(' ')} id="error-message"></p>
            <hr />
            <div style={{ marginTop: '2rem', textAlign: 'center' }}><u><a>Current Expansions</a></u></div>
            <div style={{ textAlign: 'center' }}>
                <button className={styles.buttonClass} onClick={sortExpansions}>Sort Alphabetically</button>
            </div>
            {
                RenderExpansionData.map((item, index) => {
                    return <li>
                        <div style={{ fontSize: '0.8rem' }}>
                            <strong>Shortcut:</strong>{item.shortcut}<br />
                            <strong>Expansion:</strong>
                            <span className={styles.expansionText}>{editingId !== item.id && item.expansion}</span>
                            {editingId === item.id && <textarea onChange={(e) => setListExpandedText(e.target.value)} className={styles.editInput}>{item.expansion}</textarea>}
                        </div>
                        <div className={styles.editButtons}>
                            <button onClick={() => setEditingId(item.id)} className={styles.editButton}>Edit</button>
                            <button onClick={() => deleteExpansion(item.id)}>Remove</button>
                            {editingId === item.id && <button onClick={() => editExpansion(item.shortcut, listExpandedText)} className={styles.saveButton}>Save</button>}
                            {editingId === item.id && <button onClick={() => setEditingId('')} className={styles.cancelButton}>Cancel</button>}
                        </div>
                    </li>
                }
                )
            }

        </div >
    );
};

export default TextExpander;