import React, { useEffect, useState } from 'react';
import ChargesTab from '../single/ChargesTab';
import styles from '../../assets/css/Charges.module.css';
import { useNavigate } from 'react-router-dom';
import SubmittedChargesHistory from '../single/SubmittedChargesHistory';

const Charges = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Charges');

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }
    }, [navigate]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.whiteContainer}>
                <div className={styles.tabsContainer}>
                    <div onClick={() => handleTabChange('Charges')} className={`${styles.tab} ${activeTab === 'Charges' ? styles.activeTab : ''}`}>Charges</div>
                    <div onClick={() => handleTabChange('Charges History')} className={`${styles.tab} ${activeTab === 'Charges History' ? styles.activeTab : ''}`}>Charges History</div>
                </div>
                {activeTab === 'Charges' && <ChargesTab />}
                {activeTab === 'Charges History' && <SubmittedChargesHistory />}
            </div>
            <div className='mb-6'></div>
        </div>
    );
};

export default Charges;
